import asana from "asana";
import store from "../store";

let asanaClient = asana.Client.create({
  clientId: process.env.VUE_APP_OAUTH_CLIENT_ID,
  redirectUri: `${window.location.origin}/asana-callback`,
  tokenExchangeEndpoint: process.env.VUE_APP_BACK + "/oauth_token/",
  response_type: "code"
});

if (store.getters["asanaCredentials/getAccessToken"]) {
  let credentials = {
    access_token: store.getters["asanaCredentials/getAccessToken"],
    refresh_token: store.getters["asanaCredentials/getRefreshToken"]
  };
  asanaClient.useOauth({ credentials: credentials });
}

export default asanaClient;
